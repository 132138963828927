import React, { useEffect, useRef, useState } from "react";
import "@babylonjs/loaders";
import {
  ArcRotateCamera,
  Engine,
  HemisphericLight,
  Scene,
  SceneLoader,
  Vector3,
} from "@babylonjs/core";
import {
  BodyPart,
  EngineType,
  Mode,
  ModelType,
  RooomPose,
  SelfieMode,
} from "rooompose";

let rooomPoseObj;
const Avatar = ({ video, isVideoOn, getStatus }) => {
  const canvasRef1 = useRef(null);
  const [scene, setScene] = useState(null);
  const [meshes, setMeshes] = useState(null);
  const [skeleton, setSkeleton] = useState(null);
  const [rooomPoseInstance, setRooomPoseInstance] = useState(null);

  const callRoomPose = (scene, meshes, skeleton) => {
    if (!isVideoOn) {
      // webcam stream off, clearing rooompose instance.
      if (rooomPoseObj) rooomPoseObj.stopDetection();
      setRooomPoseInstance(null);
      // TODO : call rooompose.stopDetection and don't clear rooompose instance.
      return;
    } else {
      // on webcam stream start, creating rooompose instance and calling methods.
      if (scene && meshes && video?.current) {
        rooomPoseObj = new RooomPose(
          Mode.DETECTANDRENDER,
          scene,
          meshes,
          skeleton,
          video.current,
          ModelType.RPM,
          EngineType.BABYLONJS,
          BodyPart.FULLBODY,
          SelfieMode.SELFIEMODE_ON,
          0.9,
          (status) => {
            getStatus(status);
          }
        );

        rooomPoseObj.initialize(); // calling rooompose lib initialize method
        rooomPoseObj.startDetection(); // results in avatar motion as per user's action
        setRooomPoseInstance(rooomPoseObj);
      } else {
        setRooomPoseInstance(null);
      }
    }
  };

  const createScene = (engine, canvas) => {
    // scene
    const scene = new Scene(engine);
    setScene(scene);
    // camera
    const camera0 = new ArcRotateCamera(
      "mainCamera",
      Math.PI / 2,
      Math.PI / 2,
      3,
      new Vector3(0, 1, 0),
      scene
    );
    camera0.attachControl(canvas, true);
    camera0.lowerRadiusLimit = 2;
    camera0.upperRadiusLimit = 8;
    camera0.wheelDeltaPercentage = 0.05;

    // light
    var light = new HemisphericLight("light1", new Vector3(0, 1, 0), scene);
    light.intensity = 0.9;

    // charater
    SceneLoader.ImportMesh(
      "",
      "./",
      "rpmMale.glb",
      scene,
      (meshes, particleSystems, skeletons) => {
        setMeshes(meshes);
        setSkeleton(skeletons[0]);
      }
    );

    return scene;
  };

  // this useEffect call's on every time when stream is on/off. create or clear roompose.
  useEffect(() => {
    callRoomPose(scene, meshes, skeleton);
  }, [isVideoOn]);

  // this useEffects call only once, it will handle babylon create scene logic
  useEffect(() => {
    const canvas = canvasRef1.current;
    const engine = new Engine(canvas, true);
    const scene = createScene(engine, canvas);
    engine.runRenderLoop(() => {
      scene.render();
    });

    window.addEventListener("resize", () => {
      engine.resize();
    });
  }, []);

  return (
    <div>
      <canvas className="render-canvas" ref={canvasRef1}></canvas>
    </div>
  );
};
export default Avatar;
