import "./App.css";
import Main from "./main";
function App() {
  return (
    <div
      style={{ backgroundColor: "#282c34" }}
      className="app-container"
    >
      <div className="App">
        <Main />
      </div>
      <div className="overlay"></div>
    </div>
  );
}

export default App;
