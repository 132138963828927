import React, { useState, useRef } from "react";
import Avatar from "./components/avatar";
import { STATUS } from "rooompose";
import VideoPoster from "./assets/images/video-poster.png";
const Main = () => {
  const videoRef = useRef(null);
  const [isVideoOn, setVideoON] = useState(false); // flag for video stream

  // Start camera : after starting camera the result will be targeted to promise(then)
  const startCam = async () => {
    if (videoRef.current && !isVideoOn) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setVideoON(true);
        });
    }
  };

  // Load spinner.
  function buttonClickEvent() {
    startCam();
    var ele = document.getElementsByClassName("app-container");
    ele[0].classList.add("loading");
  }

  // Stop Camera
  const stopCam = () => {
    if (isVideoOn) {
      let stream = videoRef.current.srcObject;
      stream?.getTracks().forEach((track) => track?.stop());
      videoRef.current.srcObject = null;
      setVideoON(false);
    }
  };

  // callback function to get status of library.
  const getStatus = (status) => {
    if (status === STATUS.RENDERING_STARTED) {
      var ele = document.getElementsByClassName("app-container");
      ele[0].classList.remove("loading");
    }
  };

  return (
    <div>
      {/* Header & Buttons Sections */}
      <header className="App-header">
        <div className="header-section">
          <div className="camera-btn">
            <div className="start-stop-btn">
              <button
                type="button"
                className="btn-control btn btn-primary btn-lg"
                id="Btn-start"
                disabled={isVideoOn}
                onClick={buttonClickEvent}
              >
                Start
              </button>
              <button
                type="button"
                className="btn-control btn btn-danger btn-lg"
                id="Btn-stop"
                disabled={!isVideoOn}
                onClick={stopCam}
              >
                Stop
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Video & Avatar section */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {/* Input Video section */}
        <div className="input-video">
          <video
            about="Start Video"
            ref={videoRef}
            autoPlay
            playsInline
            loop
            poster={VideoPoster}
          />
        </div>
        {/* Avatar Section */}
        <div className="avatar-render">
          <Avatar
            video={videoRef}
            isVideoOn={isVideoOn}
            getStatus={getStatus}
          />
        </div>
      </div>
    </div>
  );
};
export default Main;
